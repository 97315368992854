import React, { Component } from "react";

import Hero from "react-bulma-components/lib/components/hero/hero";
import Body from "react-bulma-components/lib/components/hero/components/hero-body";
import Container from "react-bulma-components/lib/components/container/container";
import Content from "react-bulma-components/lib/components/content/content";
import Heading from "react-bulma-components/lib/components/heading/heading";

import { Page } from "reusable-components";
import Nav from "../../components/ui/nav";
import Author from "../../components/ui/author";
import Styles from "./index.module.scss";


const NAV_ELEMS = [ {
	link: "/",
	label: "← Zur Startseite"
} ];

export default class Subpage extends Component {
	static propTypes = {};
	
	static defaultProps = {};
	
	render () {
		const {
			title,
			contentHtml,
			generalData,
			siteMetadata
		} = this.props.pageContext;

		return (
			<Page
				title={ `${ title } | ${ generalData.titleAppendix }` }
				description={ generalData.description }
				absoluteUrl={ siteMetadata.siteUrl }
				author={ siteMetadata.author }
				absoluteTeaserImagePath={ siteMetadata.siteUrl }
				language={ siteMetadata.language }
				location={ {
					...generalData.location,
					locationName: generalData.companyName,
				} }>

				<Nav navElements={ NAV_ELEMS } />

				<Hero
					size={ "medium" }
					textAlignment={ "centered" }
					className={ Styles.hero }>

					<Body>
						<Heading
							textSize={ 1 }
							textColor={ "primary" }
							textAlignment={ "centered" }
							renderAs={ "h1" }>

							{ title }

						</Heading>
					</Body>

				</Hero>

				<Container className={ "has-padding-bottom-xxl" }>

					<Content
						className={ Styles.content }
						textSize={ 5 }
						textColor={ "dark" }
						dangerouslySetInnerHTML={ {
							__html: contentHtml
								.replace(/ {2}/g, " ")
								.replace(/( )?(&nbsp;)/g, " ")
						} }
					/>

				</Container>

				<Author />
			</Page>
		);
	}
}
